import gql from "graphql-tag";
import { Guid } from "guid-string";
import moment from "moment";
import { FormSubmissionFieldsFragment } from '../generated/graphql';
import { blobReferenceFieldsFragment } from "./BlobReference";
import { formFieldsFragment } from "./Form";

export const formSubmissionFieldsFragment = gql`
    fragment formSubmissionFields on FormSubmission {
        id
        formId
        userId
        responseEmail
        details
        archived
        dateCreated
        category
    }
`;


export type FormSubmission = Omit<FormSubmissionFieldsFragment, '__typename' | 'formSubmission'>;

/**
 * Default values.
 */
export const formSubmissionDefaultValues = (): Partial<FormSubmission> => ({
    id: Guid.newGuid(),
    archived: false,
    details: '',
    responseEmail: '',
    dateCreated: moment(),
    category: ''
});