import * as React from 'react';
import { Button, Row, Col, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardTitle, CardSubtitle } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/loadingIndicator/LoadingIndicator';
import { Waypoint } from 'react-waypoint';
import { useReplaceSearchParamsEffect, useSearchParams } from '../../shared/useURLSearchParams';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../shared/searchInput/SearchInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/mainContainer/MainContainer';
import { NoResultsFound } from '../shared/noResultsFound/NoResultsFound';
import { StickyToolbar } from '../shared/stickyToolbar/StickyToolbar';
import { useNavigate } from 'react-router';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/banner/Banner';
import { useToggleStateArray } from 'use-toggle-state';
import { CardsOrTable } from '../shared/cardsOrTable/CardsOrTable';
import { usePreferredListViewMode } from '../../globalState/preferredListViewMode/usePreferredListViewMode';
import { useUsersListViewModel } from '../../api/main/profiles/viewModels/useUsersListViewModel';
import { CardsOrTableToggle } from '../shared/cardsOrTable/CardsOrTableToggle';
import { AudienceTagSelector } from '../audiences/AudienceTagSelector';
import { TagSelectorCollapseWrapper, TagSelectorHideOnMdAndAbove, TagSelectorToggleButton } from '../shared/tagSelectorCollapseWrapper/TagSelectorCollapseWrapper';

export interface UsersListProps {
    filterByRoleGroups?: Array<string>,
}

/**
 * List of administrator users.
 */
export const UsersList = (props: UsersListProps) => {
    const {
        filterByRoleGroups,
    } = props;

    const { t } = useTranslation();
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = React.useState<string>(searchParam ?? '');
    const { data: { items: allItems, audiences }, isLoading, errors: loadingErrors, fetchMore, hasMore } = useUsersListViewModel({ pageSize: undefined, });
    const [isMenuOpen, toggleMenuOpen] = useToggleStateArray();
    const [viewMode] = usePreferredListViewMode();
    const navigate = useNavigate();

    const [isAudienceTagSelected, toggleAudienceTag] = useToggleStateArray<string>([]);


    // Filter by the user's search client side so it can work when offline as well as online.
    const items = React.useMemo(() => {
        let ret = (allItems ?? []);

        const anyAudienceTagsSelected = !!audiences?.find(tag => isAudienceTagSelected(tag.id));
        if (anyAudienceTagsSelected) {
            ret = ret.filter(item => {
                // Try find a selected link.
                if (!!item.userAudiences.find(link => isAudienceTagSelected(link.audienceId))) {
                    return true;
                }

                // If we get here we have no matches in audience or tags so lets back out.
                return false;
            }
            );
        }



        // Filter by the role groups allowed.
        if (filterByRoleGroups) {
            ret = ret.filter(item => !!filterByRoleGroups?.find(it => it === item.user?.roleGroup?.id));
        }

        let lowerSearch = search.toLocaleLowerCase();

        // Filter the items being displayed.
        ret = ret.filter(item =>
            item.firstName.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            || item.lastName.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            || item.user.email.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            || (item.user?.roleGroup?.name?.toLocaleLowerCase()?.indexOf(lowerSearch) ?? -1) >= 0
            || `${item.firstName} ${item.lastName}`.toLocaleLowerCase().indexOf(lowerSearch) >= 0 // Full name in languages "first last"
            || `${item.lastName} ${item.firstName}`.toLocaleLowerCase().indexOf(lowerSearch) >= 0 // Full name in languages "last first"
        );

        return ret;
    }, [allItems, search, filterByRoleGroups, audiences, isAudienceTagSelected]);

    useReplaceSearchParamsEffect({ search: search });

    // Manage the toggle button state for filters
    const [isOpen, setIsOpen] = React.useState(false);
    // Toggle the filters
    const toggleFilters = () => setIsOpen(!isOpen);

    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col xs={12} md="auto">
                            <h1>
                                {t('usersList.title', 'Users')}
                            </h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                    <Row>
                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />

                            {/* Filters are not toggleable on anything larger than xs */}
                            <TagSelectorHideOnMdAndAbove>
                                <Row>
                                    <Col>
                                        <AudienceTagSelector tags={audiences} isSelected={tag => isAudienceTagSelected(tag.id)} toggle={tag => toggleAudienceTag(tag.id)} />
                                    </Col>
                                </Row>
                            </TagSelectorHideOnMdAndAbove>
                        </Col>
                        <Col xs="auto">
                            <Row>
                                <ButtonGroup>
                                    <LinkContainer to={'add'}>
                                        <Button color="primary">
                                            <FontAwesomeIcon icon="plus" /><> {t('usersListBase.add', 'Add')}</>
                                        </Button>
                                    </LinkContainer>
                                </ButtonGroup>
                            </Row>
                        </Col>
                        <Col xs={12} md="auto">
                            <CardsOrTableToggle />

                            <TagSelectorToggleButton isOpen={isOpen} toggle={toggleFilters} />
                        </Col>
                    </Row>

                    {/* Filters are toggleable on xs screens and hidden on all other breakpoints */}
                    <TagSelectorCollapseWrapper isOpen={isOpen} toggle={toggleFilters}>
                        <Row>
                            <Col>
                                <AudienceTagSelector tags={audiences} isSelected={tag => isAudienceTagSelected(tag.id)} toggle={tag => toggleAudienceTag(tag.id)} />
                            </Col>
                        </Row>
                    </TagSelectorCollapseWrapper>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors errors={loadingErrors} />
                <CardsOrTable
                    viewMode={viewMode}
                    items={items}
                    tableHeadings={[
                        t('usersListBase.firstName', 'First name'),
                        t('usersListBase.lastName', 'Last name'),
                        t('usersListBase.email', 'Email'),
                        t('usersListBase.roleGroup', 'Security'),
                        t('usersListBase.audiences', 'Audiences'),
                    ]}
                    columns={[
                        (item, view) => view === 'cards' ? (<CardTitle tag="h5">{item.firstName + " " + item.lastName}</CardTitle>) : null,
                        (item, view) => view === 'cards' ? null : item.firstName,
                        (item, view) => view === 'cards' ? null : item.lastName,
                        (item, view) =>
                            view === 'cards' ? (<CardSubtitle tag="h6" className="text-muted">{item.user?.email ?? ''}</CardSubtitle>)
                                : item.user?.email ?? '',
                        (item) => item.user?.roleGroup?.name,
                        // Audiences
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return (<AudienceTagSelector readOnly tags={audiences.filter(tag => !!item.userAudiences?.find(link => link.audienceId === tag.id))} />);
                        },
                    ]}
                    buttons={(item) => (
                        <ButtonGroup>
                            <LinkContainer to={`edit/${item.id}`}>
                                <Button color="primary" outline>
                                    <FontAwesomeIcon icon="edit" />
                                    <> {t('common.edit', 'Edit')}</>
                                </Button>
                            </LinkContainer>
                            <ButtonDropdown isOpen={isMenuOpen(item.id)} toggle={() => toggleMenuOpen(item.id)}>
                                <DropdownToggle color="primary" outline caret>
                                    <span className="visually-hidden">{t('common.menuDropdown', 'More')}</span>
                                </DropdownToggle>
                                <DropdownMenu end>
                                    <LinkContainer to={`delete/${item.id}`}>
                                        <DropdownItem className="text-danger">
                                            <FontAwesomeIcon icon="trash" />
                                            <> {t('common.delete', 'Delete')}</>
                                        </DropdownItem>
                                    </LinkContainer>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </ButtonGroup>
                    )}
                    onItemClick={item => navigate(`edit/${item.id}`)}
                />
                <ConditionalFragment showIf={isLoading && !items?.length}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && !items?.length}>

                    <NoResultsFound search={search} />

                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && hasMore()}>

                    <Waypoint key={items?.length ?? 0} onEnter={fetchMore} />
                    <LoadingIndicator fullWidth />

                </ConditionalFragment>
            </MainContainer>
        </>
    );
};
