import * as React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Form, FormGroup, Label, Row, Spinner } from "reactstrap";
import { useViewFormViewModel } from "../../api/main/formSubmissions/viewModels/useViewFormViewModel";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { HtmlDisplay, HtmlEditor } from "../../shared/htmlEditor";
import { useChanges } from "../../shared/useChanges";
import { BodyBackground } from "../layout/bodyBackground/BodyBackground";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { FormSubmission, formSubmissionDefaultValues } from '../../api/main/models/FormSubmission';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { useValidatorCallback } from 'pojo-validator-react';
import { useSaveFormSubmissionMutation } from '../../api/main/formSubmissions/useSaveFormSubmissionMutation';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useCurrentUser } from '../../api/main/users/useCurrentUser';
import './completeForm.scss';
import { createServiceProvider } from '../../configure/configureServices';

/**
 * Page for completing a form.
 */

export interface CompleteFormProps {
	isCreate?: boolean,
	onCreateDefaultValues?: () => Partial<FormSubmission>
}

export const CompleteForm = (props: CompleteFormProps) => {
	const { id, formId } = useParams<{ id: string | undefined, formId: string | undefined }>();

	const { t } = useTranslation();

	const { isCreate, onCreateDefaultValues } = props;

	const currentUser = useCurrentUser();

	const navigator = useNavigate();

	// Load all data.
	const {
		data: {
			model: storeModel, form
		},
		errors: loadErrors, isLoading,
	} = useViewFormViewModel(id, formId);

	const [saveFormSubmission, { errors: savingErrors, isExecuting: isSaving }] = useSaveFormSubmissionMutation();

	const { model, change, changes } = useChanges(
		storeModel,
		isCreate ?
			{ ...formSubmissionDefaultValues(), ...(onCreateDefaultValues ? onCreateDefaultValues() : {}), formId: formId } :
			undefined
	);

	React.useEffect(() => {
		if (!currentUser) {
			return;
		}

		if (currentUser.id === model?.userId) {
			return;
		}

		let email = currentUser.email;

		if (email.endsWith(".nctx")) {
			email = '';
		}

		change({ userId: currentUser.id, responseEmail: email });
	}, [currentUser, change, model]);

	const categories = React.useMemo(() => {
		if (!form?.categories) {
			return [];
		}

		return form?.categories
			.split(";")
			.map(item => item.trim())
			.filter(item => !!item);

	}, [form?.categories]);

	const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
		const rules = {
			responseEmail: () => !model?.responseEmail ? t('formSubmission.errors.responseEmail', 'Response email cannot be empty') : '',
			category: () => {
				const categoriesAvailable = !!form?.categories;
				const categoryChosen = !!model.category;
				var returnResult = !!categoriesAvailable && !categoryChosen ? t('formSubmission.errors.category', 'Category cannot be empty') : '';
				return returnResult;
			},
		};
		validation.checkRules(rules, fieldsToCheck);
	}, [model, form]);

	const save = React.useCallback(async () => {
		if (!model) {
			return;
		}

		if (!validate()) {
			return;
		}

		await saveFormSubmission(model.id, changes, isCreate ?? false);

		//Send the submission to the email addresses provided by the form object
		const serviceProvider = createServiceProvider();
		const services = serviceProvider.services();

		await services.apiFetch().post<boolean>(`/api/forms/sendnewsubmissionemails?id=${encodeURI(model.id)}`);

		navigator("/forms/thankyou");
	}, [model, changes, validate, isCreate, saveFormSubmission, navigator]);

	return (
		<>
			<BodyBackground background="forms" />

			<MainContainer className="complete-form">
				{/* Title - note this is not within a banner. */}
				<Row>
					<Col>
						<div className="complete-form-title">
							<h1>
								{form?.name ?? ''}
							</h1>
						</div>

						{
							model ? (
								<div className="complete-form-date">
									<FontAwesomeIcon icon={['far', 'clock']} />
									<> </>
									{t('common.date', '{{date, DD/MM/YYYY}}', { date: !!model.dateCreated ? moment(model.dateCreated) : moment() })}
								</div>
							) : null
						}
					</Col>
					<ConditionalFragment showIf={isLoading}>
						<Col xs="auto">
							<LoadingIndicator size="sm" />
						</Col>
					</ConditionalFragment>
				</Row>

				<AlertOnErrors errors={[
					loadErrors, savingErrors
				]} />

				 {/*Image if we have one */}
				{
					form?.blobReference?.url ? (
						<Row className="view-news-image-container">
							
							<Col xs="auto">
								<img className="img-fluid complete-form-image" src={form?.blobReference?.url ?? ''} alt={t('formSubmission.image.altText', 'Picture to support the form')} />
							</Col>
						</Row>
					) : null
				}

				{/* Main body text */}
				<HtmlDisplay html={form?.descriptionHtml ?? ''} />

				<Form onSubmit={(e) => { e.preventDefault(); save(); }}>
					<Row>
						<Col>
							<FormGroup>
								<Label>
									{t('formSubmission.responseEmail.label', 'Your email address')}
								</Label>
								<ValidatedInput name="responseEmail" type="email" value={model?.responseEmail ?? ''} onChange={e => change({ responseEmail: e.currentTarget.value })} onBlur={e => validate('responseEmail')} validationErrors={validationErrors['responseEmail']} />
							</FormGroup>
						</Col>

						<ConditionalFragment showIf={!!form?.categories}>

							<Col>
								<FormGroup>
									<Label>{t('formSubmission.category.label', 'Category')}</Label>
									<ValidatedInput name="category" type="select" value={model?.category ?? ''} onChange={e => change({ category: e.currentTarget.value })} onBlur={e => validate('category')} validationErrors={validationErrors['category']}>
										<option></option>
										{
											categories.map((item, index) => <option key={index} value={item}>{item}</option>)
										}
									</ValidatedInput>
								</FormGroup>
							</Col>
						</ConditionalFragment>
					</Row>
					<Row>
						<Col>
							<FormGroup>
								<Label for="content">{t('formSubmission.details.label', 'Form details')}</Label>
								<HtmlEditor
									size="sm"
									value={model?.details ?? ''}
									onChange={(value: string) => change({ details: value })}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<ButtonAsync color="primary" isExecuting={isSaving} onClick={() => save()}
								executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
								<FontAwesomeIcon icon="save" />
								<> {t('common.save', 'Submit')}</>
							</ButtonAsync>
						</Col>
					</Row>
				</Form>

			</MainContainer>
		</>
	);
};